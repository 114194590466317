.card-main{
    display: flex;
    flex-direction: column;
    height: 200px;
    gap:10px;
    justify-content: center;
    align-items: center;
}

.card-main-project{
    display: flex;
    flex-direction: column;
    gap:10px;
    justify-content: center;
    align-items: center;
    height: 100%; 
    min-height: 300px;
}

.card-main-project > div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex-grow: 1; 
}


.card-image:hover{
    transform: scale(1.1);
}


.card-image{
    width: 100px;
    height: auto;
    transition: transform 0.3s ease-in-out;
}

.card-image-project{
    width: 500px;
    height: 320px;
    border-radius: 5px;
}

.card-title{
    font-size: xl-larger;
    
}

.row-card{
    height:0.5px;
    margin-top: 10px;
    background-color: #FF8D00;
}

.card-project-title{
    background: linear-gradient(90deg, rgb(255, 165, 1) 0%, rgb(255, 140, 0) 100%);
    -webkit-background-clip: text; 
    -webkit-text-fill-color: transparent;
    background-clip: text;
    color: transparent;
    font-weight: bold;
    font-size:20px;
}

.card-description {
    width: 70%;
    margin: 0 auto;
    text-align: center;
    font-weight: 300;
    color: white;
    box-sizing: border-box;
    font-size: 15px;
    flex-grow: 1; 
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}


.card-tech-project{
    color: white;
    gap:5px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.btn-card-project{
    margin: 0 auto; 
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 5px;
    padding: 10px;
    color: black;
    border: 1px solid ;
    border-radius: 10px;
    background: linear-gradient(90deg, rgb(255, 165, 1) 0%, rgb(255, 140, 0) 100%);
    transition: transform 0.3s ease-in-out;
    text-decoration: none;
    font-weight: bold;
}

.btn-card-project:hover{
    transform: scale(1.1);
    color: black;
}

@media screen and (max-width: 938px ){
    .card-title{
        font-size: small;
    }

    .card-image{
        width: 70px;
    }

    .row-card{
        display: none;
    }

    .card-image-project{
        width: 300px;
        height: auto;
    }

    .card-project-title{
        font-size: 15px;
    }

    .card-description{
        width:90%; 
    }

    .card-tech-project{
        flex-direction: column;
        font-size: 12px;
    }

}

@media screen and (max-width: 481px ){
        
}

@media screen and (max-width: 320px ){

}

