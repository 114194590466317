
.content-project{
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 20px;
    
    
    
}

.projects {
    margin-top: 80px;
    display: grid;
    grid-template-columns: repeat(3, 1fr); 
    justify-content: center;
    padding: 15px;
    gap: 35px;
    width: 100%;
   
}

@media screen and (max-width: 938px ){
    .projects{
        grid-template-columns: repeat(1, 1fr);
        gap: 200px;
    }
}

@media screen and (max-width: 481px ){
        
}

@media screen and (max-width: 320px ){

}
