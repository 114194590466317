.row{
    width: 100%;
    height: 5px;
    background-color: #FF8D00;
    
}

footer{
    height: inherit;
    position: static;
    bottom: -1px;
    width: 100%;
    text-align: center;
    background-color: rgb(42,50,60);
   
}

.content-footer{
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 15px 50px 0 50px;
    
    
}

img{
    width: 200px;
}

footer h2{
    color: #FF8D00;
}

.content-contact{
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 15px;
}

.content-contact span{
    color: white;
}

.content-contact i{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 25px;
    width: 25px;
    background-color: white;
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;
}

.icons{
    display: flex;
    flex-direction: row;
    gap: 15px;
    margin: 25px;
}

.content-social a{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: white;
    height: 25px;
    width: 25px;
    color: black;
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;
}

.content-social{
   padding-top: 10px;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: first baseline;
    gap: 10px;
}

footer p{
    padding: 20px;
    text-align: center;
    color: white;
}

@media screen and (max-width: 799px ){
    .content-footer{
        flex-direction: column;
        gap: 30px;
        padding: 15px;
    }
}

