main {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    min-height: 100vh;
    width: 100%;
    background-image: url("../../assets/fundo.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    position: relative; /* Permite a sobreposição */
}

main::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 200px; /* Ajuste conforme necessário */
    background: linear-gradient(
        to bottom,
        rgba(2, 4, 3, 0) 0%,
        #020403 100%
    ); /* Gradiente que desbota a imagem */
    z-index: 1; /* Coloca sobre a imagem de fundo */
}

.content-home-1, .icon {
    position: relative; /* Mantém o conteúdo sobre o gradiente */
    z-index: 2;
}

.content-home-1{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items:flex-start;
    gap: 10px;
}

.content-home-1 span{
    font-size: larger;
    color: white;
}

.content-home-1 h1{
    color: #FF8D00;
}

.icon{
    width: 100%;
    max-width: 400px;
    border-radius: 50%;
    object-fit: cover;
    aspect-ratio: 1/1;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
}

.my-stacks {
    background: #020403;
    color: white;
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.title-home{
    padding-top:20px;
    text-align: center;
    width: 20%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    
}

.row-home{
        width: 100%;
        height:0.5px;
        margin-top: 10px;
        background-color: #FF8D00;
}

.content-stack{
    margin-top: 10px;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    justify-items: center;
    padding: 15px;
    width: 100%;
}

.content-stack-swiper{
    display: none;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-top: 10px;
    width: 100%;
    padding: 15px
}

.link{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 15px;
    width: 200px;
    height: 60px;
    color: white;
    background-color: #FF8D00 ;
    border: none;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
    transition: transform 0.3s ease-in-out;
    text-align: center;
    font-size: large;
    cursor: pointer;
    text-decoration: none;
}

.link:hover{
    transform: scale(1.1);
    color: white;
  
}

@media screen and (max-width: 938px ){
    .icon{
        max-width: 200px;
    }

    .content-home-1 span{
        font-size: medium;
    }

    .content-home-1 h1{
        font-size: x-large;
    }

    .title-home{
        width: 50%;
        font-size: medium;
    }

    .content-stack{
        display: none;
    }

    .content-stack-swiper{
        display: flex;
    }

    .link{
        width: 150px;
        height: 50px;
        padding: 10px;
        font-size: small;
    }

}

@media screen and (max-width: 481px ){
        main{
            flex-direction: column;
            align-items: center;
            background-position: center;
        }

        .content-home-1{
            justify-content: center;
            align-items: center;
            gap: 15px;
        }

        .content-stack{
            display: none;
        }
}

@media screen and (max-width: 320px ){

}