*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }
  
  html, body,  #root{
    min-height: 100vh;
    display: flex;
    flex-direction: column;
  }

  body{
    background-color: rgb(23,34,49);
    font:14px;
    font-family: 'JetBrains Mono', monospace;

  
  }

  a{
    text-decoration: none;
  
  }