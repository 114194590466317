header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    background-color: rgb(42,50,60);
    color: white;
    height: 100px;
    width: 100%;
    padding: 5px 20px;
    position: relative;
    z-index: 1001; /* Ensure header is above the overlay */
}

.logo img {
    width: 200px;
    padding-left: 10%;
}

nav {
    padding-right: 10%;
}

ul {
    display: flex;
    flex-direction: row;
    gap: 25px;
}

li {
    text-decoration: none;
    list-style: none;
}

a {
    text-decoration: none;
    color: white;
    transition: 0.5s;
    -webkit-transition: 0.5s;
    -moz-transition: 0.5s;
    -ms-transition: 0.5s;
    -o-transition: 0.5s;
}

a:hover {
    color: #FF8D00; 
}

.menu-icon {
    display: none;
    font-size: 30px;
    cursor: pointer;
    color: white;
}

/* Mobile styles */
@media screen and (max-width: 799px) {
    .menu-icon {
        display: block;
        margin-left: 10%; /* Align the icon to the left */
    }

    nav {
        position: fixed;
        top: 0;
        left: -250px;
        height: 100%;
        width: 250px;
        background-color: rgb(42,50,60);
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
        border-radius: 0;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        transition: left 0.3s ease;
        z-index: 1001;
    }

    nav.open {
        left: 0;
    }

    nav ul {
        flex-direction: column;
        padding: 0;
        width: 100%;
    }

    nav ul li {
        margin: 20px 0;
        text-align: center;
        width: 100%;
    }

    .overlay {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, 0.5);
        z-index: 1000;
        display: none;
    }

    .overlay.open {
        display: block;
    }
}
