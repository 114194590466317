.inputs{
    width: 100%;
    border-width: 2px;
    border-radius: 0.375rem;
    height: 2.75rem;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
}
.error-input{
    margin-top: 0.25rem;
    margin-bottom: 0.25rem;
    color: #ef4444;
}