.content-contact-page{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 60%;
    height: 60vh;
    margin: 30px;
    border-radius: 20px;
    background-color: #21262F;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.8);
}

.forms-contact{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.5rem;
    margin-top: 0.5rem;
    padding: 10px 100px 10px 100px;
}

.message-input{
    border-width: 2px;
    width: 100%;
    border-radius: 0.375rem;
    height: 6rem;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
}

.btn-send-contact{
    display: flex;
    flex-direction: column;
    padding: 20px;
    width: 150px;
    align-items: center;
    background-color: #017BFF;
    border-radius: 10px;
    border: none;
    color: white;
    font-weight: bold;
    font-size: 17px; 
    transition: transform 0.3s ease-in-out;
    cursor: pointer;
}

.btn-send-contact:hover{
    transform: scale(1.1);
}

@media screen and (max-width: 938px ){
    .content-contact-page{
        width: 100%;
        background-color: transparent;
        border: none;
        box-shadow: none;
        height: 900px;
    }

    .forms-contact{
        padding: 10px;
    }


    

   
}

@media screen and (max-width: 481px ){
}

@media screen and (max-width: 320px ){

}
