.content-about{
    display: flex;
    flex-direction: column;
    align-items: center;
    
    height: 100vh;
    
}

.title > .row{
    
    width: 70%;
    height:0.5px;
    margin-top: 10px;
    background-color: #FF8D00;
}

.title{
    align-items: center;
    color: white;
    padding-top: 15px;
    display: flex;
    flex-direction: column;
}

.about{
    display: flex;
    flex-direction: row;
    gap: 250px;
    justify-content: center;
    align-items: center;
}

.about img{
    
        width: 500px;
        height: auto;
   
}

.about article{
    
   padding: 15px;
   color: white;
   line-height: 1.6;
   font-size: 16px;
   max-width: 800px;
    margin: 0 auto;



}



@media screen and (max-width: 938px ){



    .content-about{
        height: 1000px;
    }
    .about{
        flex-direction: column;
        gap:15px;
    }

    .about article{
        padding: 30px; /* Ajuste o espaçamento interno */
        margin: 30px; /* Adicione margens ao redor do article */
        max-width: 600px; /* Reduza a largura máxima do article, se necessário */
        text-align: center;
        font-size: smaller;
    }

    .about img{
        width: 250px;
    }

   
}

@media screen and (max-width: 481px ){
}

@media screen and (max-width: 320px ){

}

